import * as React from 'react';
import styled from 'styled-components';

interface IChartFilter {
  children?: React.ReactNode;
  className?: string;
}

const ChartFilter: React.SFC<IChartFilter> = ({ children, className }) => (
  <section className={className}>{children}</section>
);

const StyledChartFilter = styled(ChartFilter)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  width: auto;
  height: 1.4375rem;
  margin: 0 0;
  padding: 0 0;
`;

export default StyledChartFilter;
