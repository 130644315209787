import * as React from 'react';
import styled from 'styled-components';

interface ITabNavItemProps {
  tabTitle: string;
  active?: boolean;
  onClick?: () => void;
  className?: string;
}

const TabNavItem: React.SFC<ITabNavItemProps> = ({
  tabTitle,
  onClick,
  className,
}) => (
  <span className={className} onClick={onClick}>
    {tabTitle}
  </span>
);

const StyledTabNavItem = styled(TabNavItem)`
  display: flex;
  flex-flow: row no-wrap;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 0;
  width: auto;
  min-width: 2rem;
  height: 1.625rem;
  margin: 0 1.25rem 0 0;
  padding: 0 0;
  position: relative;
  top: 0.125rem;
  border-bottom: 0.125rem solid rgba(249, 249, 249, 1);
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.875rem;
  line-height: 100%;
  color: ${(props) => props.theme.colorGray};
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.bpSmall}) {
    margin: 0 ${(props) => props.theme.gridGapSmall} 0 0;
  }

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    margin: 0 ${(props) => props.theme.gridGapMedium} 0 0;
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    margin: 0 3rem 0 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(props) => props.active && activeStyles};
`;

const activeStyles = `
    border-bottom-color: rgba(200, 176, 130, 1);
    color: rgba(200, 176, 130, 1);
`;

export default StyledTabNavItem;
