import * as React from 'react';
import styled from 'styled-components';

interface ChartLabelProps {
  label: string;
  className?: string;
}

const ChartLabel: React.SFC<ChartLabelProps> = ({ label, className }) => (
  <label className={className}>{label}</label>
);

const StyledChartLabel = styled(ChartLabel)`
  display: flex;
  width: auto;
  height: auto;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.1em;
  color: ${(props) => props.theme.typeBody};

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    font-size: 0.75rem;
  }
`;

export default StyledChartLabel;
