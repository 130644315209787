import * as React from 'react';
import styled from 'styled-components';

interface NavSectionProps {
  title: string;
  children?: React.ReactNode;
}

const NavSection: React.SFC<NavSectionProps> = ({ title, children }) => (
  <Layout>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </Layout>
);

const Layout = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 0 2.5rem;
  padding: 0 0;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    align-items: flex-start;
  }

  &:last-child {
    margin: 0 0 0;
  }
`;

const SectionTitle = styled.label`
  display: flex;
  margin: 0 0 0.875rem;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.025em;
  color: rgba(255, 255, 255, 0.2);

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    padding: 0 1rem 0 2.25rem;
  }
`;

export default NavSection;
