import * as React from 'react';
import styled from 'styled-components';

interface ChartLayoutProps {
  className?: string;
  children?: React.ReactNode;
}

const ChartLayout: React.SFC<ChartLayoutProps> = ({ className, children }) => (
  <section className={className}>{children}</section>
);

const StyledChartLayout = styled(ChartLayout)`
  display: grid;
  grid-gap: 1.25rem;
  grid-template-areas:
    'ChartHeader'
    'Chart';
  grid-template-columns: 100%;
  grid-template-rows: auto;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;
`;

export default StyledChartLayout;
