import * as React from 'react';
import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

interface INavItemProps {
  to?: string;
  children?: any;
  className?: string;
}

const NavItem: React.SFC<INavItemProps> = ({ to, children, className }) => {
  if (to) {
    return (
      <NavLink exact to={to} className={className} activeClassName="activeItem">
        {children}
      </NavLink>
    );
  }

  return <span className={className}>{children}</span>;
};

const StyledNavItem = styled(NavItem)`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 0 1rem;
  padding: 1rem 0;
  background: none;
  border-bottom: 0.125rem solid transparent;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.9375rem;
  line-height: 100%;
  letter-spacing: 0.01rem;
  text-decoration: none;
  text-align: inherit;
  color: ${(props) => props.theme.colorWhite};
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
    height: 1.375rem;
    margin: 0 0 1.25rem;
    padding: 0 1rem 0 2rem;
    border-bottom: none;
    border-left: 0.25rem solid transparent;
  }

  &:hover,
  &:active,
  &:visited {
    color: ${(props) => props.theme.colorWhite};
  }

  &:hover {
    border-bottom-color: ${(props) => props.theme.UIHighlight};

    @media (min-width: ${(props) => props.theme.bpLarge}) {
      border-left-color: ${(props) => props.theme.UIHighlight};
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.activeItem {
    border-bottom-color: ${(props) => props.theme.UIHighlight};
    color: ${(props) => props.theme.colorWhite};

    @media (min-width: ${(props) => props.theme.bpLarge}) {
      border-bottom: none;
      border-left-color: ${(props) => props.theme.UIHighlight};
    }
  }
`;

export default StyledNavItem;
