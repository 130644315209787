import * as React from 'react';
import styled from 'styled-components';

import { defaultTheme as theme } from '@gerbil/theme';

import Icon from '../icon';
import { Tr, Td } from '../table';
import User from '../user';

interface UserRowHoursProps {
  userName: string;
  billed: number;
  forecasted?: number;
  diff?: number;
  children?: React.ReactNode;
  className?: string;
}

interface State {
  detailOpen: boolean;
}

class UserRowHours extends React.Component<UserRowHoursProps, State> {
  state = {
    detailOpen: false,
  };

  toggleDetailView = () => {
    const { detailOpen } = this.state;
    this.setState({
      detailOpen: !detailOpen,
    });
  };

  render() {
    const {
      userName,
      billed,
      children,
    } = this.props;

    return (
      <React.Fragment>
        <Tr>
          <Td align="left">
            <User name={userName} />
          </Td>
          <Td align="center">{billed.toFixed(2)}</Td>
          <DropDown onClick={this.toggleDetailView}>
            <Icon icon="UIDropdown" color="gold" />
          </DropDown>
        </Tr>

        {this.state.detailOpen && <ChildWrap>{children}</ChildWrap>}
      </React.Fragment>
    );
  }
}

const DropDown = styled.span`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 100%;
  margin: 0 1rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const AlertStyles = `
  color: ${theme.colorAlert};
`;

const WarningStyles = `
  color: ${theme.colorWarning};
`;

const StyledUserRowHours = styled(UserRowHours)`
  font-weight: ${(props) => props.theme.fontWeightBold};
  ${(props) => props.diff < 0 && AlertStyles};
  ${(props) => props.diff > 0 && WarningStyles};
`;

const ChildWrap = styled.section`
  width: 100%;
`;

export default StyledUserRowHours;
