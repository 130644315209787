import { call, put, createEffect } from 'redux-cofx';
import apiFetch from '@gerbil/fetch';
import { Money, MoneyClient } from '@gerbil/types';

import { actions } from './slice';

interface MoneyResponse {
  clients: string[];
  dates: string[];
  dollars: MoneyClient;
}

function transformMoney(m: MoneyResponse): Money {
  return {
    dates: m.dates,
    dollars: m.dollars,
  };
}

export function* onFetchMoney() {
  const res = yield call(apiFetch, '/money');

  if (res.status < 200 || res.status >= 300) {
    // do something with error
    return;
  }

  const data = transformMoney(res.body as MoneyResponse);
  yield put(actions.setMoney(data));
}

const fetchMoney = () => createEffect(onFetchMoney);

export const effects = { fetchMoney, onFetchMoney };
