import robodux from '@gerbil/robodux';
import { InvoiceState } from '@gerbil/types';

const slice = 'invoice';

const defaultInvoice = {};

const { actions, reducer } = robodux<InvoiceState>({
  actions: {
    setInvoice: (state: InvoiceState, payload: InvoiceState) => payload,
  },
  initialState: defaultInvoice,
  slice,
});

const reducers = {
  [slice]: reducer,
};

export { actions, reducers, slice };
