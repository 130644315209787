import * as React from 'react';
import styled from 'styled-components';

interface ThProps {
  align?: 'left' | 'center';
  className?: string;
  children?: React.ReactNode;
}

const Th: React.SFC<ThProps> = ({ align = 'left', className, children }) => (
  <th className={className}>{children}</th>
);

const StyledTh = styled(Th)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${(props) =>
    props.align === 'center' ? 'center' : 'flex-start'};
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 1.75rem ${(props) => props.theme.tablePad} 1.5rem;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.875rem;
  line-height: 100%;
  color: ${(props) => props.theme.typeBody};
`;

export default StyledTh;
