import { call, put, createEffect, all } from 'redux-cofx';

import apiFetch from '@gerbil/fetch';
import { actions } from './slice';
import {
  DeliveryMetricsDatum,
  WeeklyMetrics,
  MonthlyMetrics,
} from '@gerbil/types';

interface WeeklyMetricsResponse {
  'billed-on-friday': DeliveryMetricsDatum[];
  'hours-underbilled': DeliveryMetricsDatum[];
  'investment-on-friday': DeliveryMetricsDatum[];
}

function transformWeeklyMetrics(w: WeeklyMetricsResponse): WeeklyMetrics {
  return {
    billedOnFriday: w['billed-on-friday'],
    hoursUnderbilled: w['hours-underbilled'],
    investmentOnFriday: w['investment-on-friday'],
  };
}

interface MonthlyMetricsResponse {
  'average-bill-rate-by-month': DeliveryMetricsDatum[];
}

function transformMonthlyMetrics(w: MonthlyMetricsResponse): MonthlyMetrics {
  return {
    averageBillRateByMonth: w['average-bill-rate-by-month'],
  };
}

export function* fetchWeeklyMetrics() {
  const res = yield call(apiFetch, '/report_delivery_metrics');

  if (res.status < 200 || res.status >= 300) {
    return;
  }

  const data: WeeklyMetricsResponse = res.body;
  return transformWeeklyMetrics(data);
}

export function* fetchMonthlyMetrics() {
  const res = yield call(apiFetch, '/report_delivery_monthly_metrics');

  if (res.status < 200 || res.status >= 300) {
    return;
  }

  const data: MonthlyMetricsResponse = res.body;
  return transformMonthlyMetrics(data);
}

interface DeliveryMetricsResponse {
  [key: string]: DeliveryMetricsDatum[];
}

export function* onFetchDeliveryMetrics() {
  const resp: DeliveryMetricsResponse[] = yield all([
    call(fetchWeeklyMetrics),
    call(fetchMonthlyMetrics),
  ]);
  const data = resp.filter(Boolean).reduce((acc, metrics) => {
    return { ...acc, ...metrics };
  }, {});

  yield put(actions.setDeliveryMetrics(data));
}

const fetchDeliveryMetrics = () => createEffect(onFetchDeliveryMetrics);

export const effects = { fetchDeliveryMetrics, onFetchDeliveryMetrics };
