import * as React from 'react';

import ButtonRefresh from '../button-refresh';
import ForecastChart from '../chart-forecast';
import Header from '../header';
import { LayoutPage } from '../layout';

export default () => (
  <LayoutPage>
    <Header type="page" text="Forecast">
      <ButtonRefresh />
    </Header>

    <ForecastChart />
  </LayoutPage>
);
