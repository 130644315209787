import robodux from '@gerbil/robodux';
import { WebState, ApiEndpointState } from '@gerbil/types';

const slice = 'apiEndpoint';
const { actions, reducer } = robodux({
  slice,
  initialState: '',
  actions: {
    setApiEndpoint: (state: ApiEndpointState, payload: ApiEndpointState) =>
      payload,
  },
});

const selectors = {
  getApiEndpoint: (state: WebState) => state[slice],
};

const reducers = {
  [slice]: reducer,
};

export { actions, reducers, selectors };
