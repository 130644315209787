import * as React from 'react';

import styled from 'styled-components';
import { defaultTheme as theme } from '@gerbil/theme';

interface MetricProps {
  className?: string;
  label?: string;
  data?: number;
}

const MetricDelta: React.SFC<MetricProps> = ({
  className,
  label,
  data = '0',
}) => (
  <Layout>
    <Label>{label}</Label>
    <span className={className}>{data}</span>
  </Layout>
);

const Layout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 0;
  padding: 0 0;
  text-align: center;
`;

const Label = styled.label`
  display: flex;
  width: auto;
  height: auto;
  margin: 0 0 0.5rem;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 0.625rem;
  line-height: 100%;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  text-align: inherit;
  color: ${(props) => props.theme.typeBodyLight};

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    font-size: 0.75rem;
  }
`;

const AlertStyles = `
  color: ${theme.colorAlert};
`;

const WarningStyles = `
  color: ${theme.colorWarning};
`;

const StyledMetricDelta = styled(MetricDelta)`
  display: flex;
  width: auto;
  height: auto;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 1.25rem;
  line-height: 100%;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: inherit;
  color: ${theme.darkGray};

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    font-size: 1.5rem;
  }

  ${(props) => props.data > 750 && AlertStyles};
  ${(props) => props.data < -100 && WarningStyles};
  ${(props) => props.data < -500 && AlertStyles};
`;

export default StyledMetricDelta;
