import robodux from '@gerbil/robodux';
import { DeliveryMetrics, WebState } from '@gerbil/types';

const slice = 'deliveryMetrics';

const defaultDeliveryMetrics: DeliveryMetrics = {
  averageBillRateByMonth: [],
  billedOnFriday: [],
  hoursUnderbilled: [],
  investmentOnFriday: [],
};

const { actions, reducer } = robodux<DeliveryMetrics>({
  actions: {
    setDeliveryMetrics: (state: DeliveryMetrics, payload: DeliveryMetrics) =>
      payload,
  },
  initialState: defaultDeliveryMetrics,
  slice,
});

const reducers = {
  [slice]: reducer,
};

const selectors = {
  getDeliveryMetrics: (state: WebState) => state[slice],
};

export { actions, reducers, slice, selectors };
