import { call, put, select } from 'redux-cofx';
import { push } from 'connected-react-router';

import { selectors, effects } from '@gerbil/auth';
const { getIsAuthenticated, getAuthInfo } = selectors;
const { onCheckAuth } = effects;
import { effects as bootupEffects } from '@gerbil/bootup';
const { fetchData } = bootupEffects;

import { INDEX, LOGIN, MONEY, INVOICE, LAST_WEEK_HOURS } from './routes';

export interface Route {
  isExact: boolean;
  params: { [key: string]: string };
  path: string;
  url: string;
}

export const webRouterMap = {
  [INDEX]: onIndex,
  [LOGIN]: redirectIfLoggedIn,
  [MONEY]: authRequired,
  [INVOICE]: authRequired,
  [LAST_WEEK_HOURS]: authRequired,
};

export function* onIndex() {
  const options = yield select(getAuthInfo);
  const opts = { options, hash: window.location.hash };
  const token = yield call(onCheckAuth, opts);
  if (token) {
    yield put(fetchData());
  }
  yield call(authRequired);
}

export function* authRequired(route: Route) {
  const isAuthed = yield select(getIsAuthenticated);
  if (!isAuthed) {
    yield put(push(LOGIN));
  }

  return isAuthed;
}

export function* redirectIfLoggedIn(route: Route) {
  const isAuthed = yield select(getIsAuthenticated);
  if (isAuthed) {
    yield put(push(INDEX));
  }

  return isAuthed;
}
