import { defaultTheme as theme } from '@gerbil/theme';

// Colors
const colors = [
  theme.colorChart1,
  theme.colorChart2,
  theme.colorChart3,
  theme.colorChart4,
  theme.colorChart5,
  theme.colorChart6,
  theme.colorChart7,
  theme.colorChart8,
  theme.colorChart9,
  theme.colorChart10,
  theme.colorChart11,
  theme.colorChart12,
];

// Strokes
const strokeLinecap = 'square';
const strokeLinejoin = 'arcs';

// Base Layout
const baseProps = {
  height: 500,
  padding: 0,
  domainPadding: 40,
  colorScale: colors,
};

// Labels
const baseLabelStyles = {
  padding: 20,
  fill: theme.typeBody,
  stroke: 'transparent',
  fontFamily: theme.fontFamilyUI,
  fontWeight: theme.fontWeightReg,
  fontSize: 12,
  letterSpacing: '.25px',
  textTransform: 'uppercase',
};

const centeredLabelStyles = { ...baseLabelStyles, textAnchor: 'middle' };

export default {
  axis: {
    ...baseProps,
    style: {
      axis: {
        fill: 'transparent',
        stroke: 'rgb(238, 238, 238)',
        strokeWidth: 1,
        strokeLinecap,
        strokeLinejoin,
      },
      axisLabel: {
        ...centeredLabelStyles,
        padding: 20,
      },
      grid: {
        fill: 'none',
        stroke: 'rgba(238, 238, 238, .75)',
        strokeWidth: 1,
        pointerEvents: 'painted',
      },
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },
      tickLabels: baseLabelStyles,
    },
  },

  bar: {
    ...baseProps,
    style: {
      data: {
        fill: '#000',
        fillOpacity: 0.6,
        strokeWidth: 1,
      },
      labels: baseLabelStyles,
    },
  },

  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
      title: baseLabelStyles,
    },
  },

  line: {
    ...baseProps,
    interpolation: 'cardinal',
    style: {
      data: {
        fill: 'transparent',
        stroke: theme.colorChart1,
        strokeWidth: 3,
      },
      labels: centeredLabelStyles,
    },
    colorScale: colors,
  },

  stack: {
    ...baseProps,
    colorScale: colors,
  },
};
