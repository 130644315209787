import * as React from 'react';
import styled from 'styled-components';

import ButtonLogout from '../button-logout';
import { NavItem, NavMain, NavSection, NavUtility } from '../navigation';

interface LayoutPageProps {
  authenticated?: boolean;
  children?: React.ReactNode;
  className?: string;
}

interface IMobileNavState {
  navOpen: boolean;
}

class LayoutPage extends React.Component<LayoutPageProps, IMobileNavState> {
  state = {
    navOpen: false,
  };

  toggleMobileNav = () => {
    const { navOpen } = this.state;
    this.setState({
      navOpen: !navOpen,
    });
  };

  render() {
    const { authenticated = true, children, className } = this.props;
    return (
      <div className={className}>
        <NavMain
          authenticated={authenticated}
          navOpen={this.state.navOpen}
          toggleNav={this.toggleMobileNav}
        >
          <NavSection title="Delivery">
            <NavItem to="/">Forecast</NavItem>
            <NavItem to="/delivery-metrics">Delivery Metrics</NavItem>
          </NavSection>

          <NavSection title="Finance">
            <NavItem to="/money">Money Forecast</NavItem>
            <NavItem to="/invoice">Invoice Check</NavItem>
          </NavSection>

          <NavSection title="Last Week">
            <NavItem to="/last-week-hours">Last Week's Hours</NavItem>
          </NavSection>
        </NavMain>

        <NavUtility toggleNav={this.toggleMobileNav}>
          {authenticated && <ButtonLogout />}
        </NavUtility>

        <ContentContainer>{children}</ContentContainer>
      </div>
    );
  }
}

const StyledLayoutPage = styled(LayoutPage)`
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    'header'
    'utility'
    'main';
  grid-template-columns: 100%;
  grid-template-rows: 0 4.25rem auto;
  width: 100%;
  height: auto;
  min-height: 100vh;
  margin: 0 0;
  padding: 0 0;
  position: relative;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    grid-template-areas:
      'header utility'
      'header main';
    grid-template-columns: 15rem 1fr;
    grid-template-rows: 4.25rem auto;
    height: 100vh;
    overflow: hidden;
  }
`;

const ContentContainer = styled.main`
  grid-area: main;
  width: 100%;
  height: auto;
  padding: ${(props) => props.theme.gridGapSmall};
  overflow: scroll;

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    padding: ${(props) => props.theme.gridGapMedium};
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    padding: ${(props) => props.theme.gridGap};
  }
`;

export default StyledLayoutPage;
