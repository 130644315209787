import * as React from 'react';

import TabNavItem from './tab-nav-item';
import TabNav from './tab-nav';

interface ITabGroupProps {
  children?: any;
  className?: string;
}

class TabGroup extends React.Component<ITabGroupProps, any> {
  state = {
    activeTab: 0,
  };

  buildTabs = () => {
    const { children } = this.props;

    return React.Children.map(children, (tab: any, index: number) => {
      if (!tab || typeof tab === 'string') {
        return tab;
      }

      const { tabTitle } = tab.props;
      return (
        <TabNavItem
          active={this.state.activeTab === index}
          onClick={this.changeTab(index)}
          key={tabTitle}
          tabTitle={tabTitle}
        />
      );
    });
  };

  changeTab = (index: number) => {
    return () => {
      this.setState({ activeTab: index });
    };
  };

  render() {
    const { children, className } = this.props;

    return (
      <section className={className}>
        <TabNav>{this.buildTabs()}</TabNav>
        {children[this.state.activeTab]}
      </section>
    );
  }
}

export default TabGroup;
