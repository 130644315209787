import * as React from 'react';
import { connect } from 'react-redux';
import { AuthOptions } from 'auth0-js';
import { Dispatch } from 'redux';

import { routes } from '@gerbil/router';
const { INDEX } = routes;
import { WebState } from '@gerbil/types';
import { effects, selectors } from '@gerbil/auth';
const { auth } = effects;
const { getAuthInfo, getIsAuthenticated } = selectors;

import Button from '../button';
import { Link } from 'react-router-dom';

interface IState {
  authInfo: AuthOptions;
  isAuthed?: boolean;
}

interface IDispatch {
  onClick: (options: AuthOptions) => void;
}

interface IProps extends IState, IDispatch {}

export const ButtonLogin = ({
  onClick,
  authInfo,
  isAuthed = false,
}: IProps) => {
  if (isAuthed) {
    return (
      <div>
        <div>User is authenticated!</div>
        <Link to={INDEX}>Home</Link>
      </div>
    );
  }
  return (
    <Button type="primary" icon="UILock" onClick={() => onClick(authInfo)}>
      Login With Google
    </Button>
  );
};

export const mapState = (state: WebState) => ({
  authInfo: getAuthInfo(state),
  isAuthed: getIsAuthenticated(state),
});
export const mapDispatch = (dispatch: Dispatch) => ({
  onClick: (options: AuthOptions) => dispatch(auth(options)),
});

export default connect<IState, IDispatch>(
  mapState,
  mapDispatch,
)(ButtonLogin);
