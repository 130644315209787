import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import Icon from '../icon';
import { H4 } from '../typography';

interface ILoaderProps {
  text?: string;
  className?: string;
}

const Loader: React.SFC<ILoaderProps> = ({ text = 'Loading', className }) => (
  <div className={className}>
    <IconLoad>
      <Icon icon="UIRefresh" color="gold" />
    </IconLoad>
    <Text>{text}</Text>
  </div>
);

const StyledLoader = styled(Loader)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 10rem;
  margin: 0 0;
  padding: 0 0;
`;

const Rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const IconLoad = styled.span`
  display: flex;
  width: 2rem;
  height: 2rem;
  margin: 0 0;
  animation: ${Rotate360} 1200ms ${(props) => props.theme.circInOut} infinite;
`;

const Text = styled(H4)`
  display: flex;
  width: auto;
  margin: 0.875rem 0 0 0;
`;

export default StyledLoader;
