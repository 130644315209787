import styled from 'styled-components';
import { defaultTheme as theme } from '@gerbil/theme';

// Headlines
export const H1 = (styled.h1 as any)`
    width: 100%;
    margin: 0 0 .5em;
    padding: 0 0;
    font-family: ${theme.fontFamilyUI};
    font-weight: ${theme.fontWeightSemi};
    font-size: 1.25rem;
    line-height: 116%;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: inherit;
    color: ${theme.typeHeadlineDark};

    @media (min-width: ${theme.bpSmall}) {
        font-size: 1.375rem;
    }

    @media (min-width: ${theme.bpLarge}) {
        font-size: 1.5rem;
    }
`;

export const H2 = (styled.h2 as any)`
    width: 100%;
    margin: 0 0 .5em;
    padding: 0 0;
    font-family: ${theme.fontFamilyUI};
    font-weight: ${theme.fontWeightSemi};
    font-size: 1rem;
    line-height: 120%;
    letter-spacing: .01em;
    text-transform: uppercase;
    text-align: inherit;
    color: ${theme.typeHeadlineDark};

    @media (min-width: ${theme.bpSmall}) {
        font-size: 1.125rem;
    }

    @media (min-width: ${theme.bpLarge}) {
        font-size: 1.25rem;
    }
`;

export const H3 = (styled.h3 as any)`
    width: 100%;
    margin: 0 0 .5em;
    padding: 0 0;
    font-family: ${theme.fontFamilyUI};
    font-weight: ${theme.fontWeightSemi};
    font-size: .875rem;
    line-height: 125%;
    letter-spacing: .02em;
    text-transform: uppercase;
    text-align: inherit;
    color: ${theme.typeHeadlineDark};

    @media (min-width: ${theme.bpSmall}) {
        font-size: .9375rem;
    }

    @media (min-width: ${theme.bpLarge}) {
        font-size: 1rem;
    }
`;

export const H4 = (styled.h4 as any)`
    width: 100%;
    margin: 0 0 .5em;
    padding: 0 0;
    font-family: ${theme.fontFamilyUI};
    font-weight: ${theme.fontWeightReg};
    font-size: .75rem;
    line-height: 116%;
    letter-spacing: .171em;
    text-transform: uppercase;
    text-align: inherit;
    color: ${theme.typeHeadlineLight};
`;

// Paragraph
export const Text = (styled.p as any)`
    width: 100%;
    margin: 0 0 .5em;
    padding: 0 0;
    font-family: ${theme.fontFamilyUI};
    font-weight: ${theme.fontWeightReg};
    font-size: .875rem;
    line-height: 150%;
    letter-spacing: -.012em;
    color: ${theme.typeBody};

    &:last-child {
        margin: 0 0 0;
    }
`;
