import { call, put, createEffect } from 'redux-cofx';

import apiFetch from '@gerbil/fetch';
import { Invoice } from '@gerbil/types';

import { actions } from './slice';

interface InvoiceResponseData {
  [key: string]: number | string;
}

interface InvoiceResponse {
  client_name: InvoiceResponseData;
  delta: InvoiceResponseData;
  forecasted: InvoiceResponseData;
  invoiced: InvoiceResponseData;
  work_week: InvoiceResponseData;
}

function transformInvoice(i: InvoiceResponse): Invoice {
  const clients = Object.keys(i.client_name);
  const data = clients.reduce((acc: any, client: string) => {
    acc[client] = {
      clientName: client,
      delta: i.delta[client],
      forecasted: i.forecasted[client],
      invoiced: i.invoiced[client],
      workWeek: i.work_week[client],
    };

    return acc;
  }, {});

  return data;
}

export function* onFetchInvoice() {
  const res = yield call(apiFetch, '/invoice_check');

  if (res.status < 200 || res.status >= 300) {
    // do something with error
    return;
  }

  const data = transformInvoice(res.body);
  yield put(actions.setInvoice(data));
}

const fetchInvoice = () => createEffect(onFetchInvoice);

export const effects = { fetchInvoice, onFetchInvoice };
