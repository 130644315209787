import { select, call, put } from 'redux-cofx';
import * as debug from 'debug';

import { selectors, effects } from '@gerbil/auth';
const { getToken } = selectors;
const { logout } = effects;

import { selectors as fetchSelectors } from './slice';
const { getApiEndpoint } = fetchSelectors;

const log = debug('app:fetch');

interface FetchOptions {
  auth?: boolean;
}

type ApiOpts = RequestInit & FetchOptions;

export default function*(uri: string, opts: ApiOpts = {}) {
  const auth = typeof opts.auth === 'undefined' ? true : opts.auth;
  const options = { ...opts };
  delete options.auth;

  const domain = yield select(getApiEndpoint);

  const url = `${domain}${uri}`;

  if (auth) {
    const token = yield select(getToken);

    if (!token) {
      return { status: 401, body: {} };
    }

    const headers = <any>options.headers || {};
    headers.Authorization = `Bearer ${token}`;
    options.headers = headers;
  }

  const res = yield call(fetch, url, options);
  const body = yield call([res, 'json']);
  if (res.status === 401) {
    log('looks like user is not authenticated');
    yield put(logout());
  }

  return { status: res.status, body };
}
