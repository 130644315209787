import * as React from 'react';

import {
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
} from 'victory';
import { DeliveryMetricsDatum } from '@gerbil/types';

import { ChartTheme, ChartWrap } from '../chart';
import EmptyState from '../empty-state';
import Header from '../header';

function formatDate(date: string) {
  const d = new Date(`${date}T00:00:00`);
  return `${d.getMonth() + 1} / ${d.getDate()}`;
}

function formatY(d: DeliveryMetricsDatum) {
  return parseInt(Math.abs(d[1]).toFixed(0), 10);
}

interface IChartBillRateProps {
  title: string;
  data?: any;
  yLabel?: string;
}

export class ChartBillRate extends React.Component<IChartBillRateProps, any> {
  static defaultProps = {
    yLabel: 'Hours',
  };

  render() {
    const { title, data, yLabel } = this.props;

    return (
      <React.Fragment>
        <Header type="section" color="light" text={title} />

        {!data && <EmptyState />}

        {data && (
          <ChartWrap>
            {(width) => (
              <VictoryChart
                containerComponent={<VictoryContainer responsive={false} />}
                width={width}
                height={400}
                standalone={true}
                theme={ChartTheme}
                animate={{ duration: 350, onLoad: { duration: 350 } }}
                padding={{
                  top: 20,
                  right: 20,
                  bottom: 40,
                  left: 80,
                }}
              >
                <VictoryAxis
                  tickValues={data.dates}
                  tickFormat={formatDate}
                  axisLabelComponent={<VictoryLabel dy={0} />}
                />
                <VictoryAxis
                  dependentAxis={true}
                  label={yLabel}
                  axisLabelComponent={<VictoryLabel dy={-40} />}
                />
                <VictoryLine data={data} x={0} y={formatY} labels={formatY} />
              </VictoryChart>
            )}
          </ChartWrap>
        )}
      </React.Fragment>
    );
  }
}

export default ChartBillRate;
