// Color Variables To Reuse Across Theme Variables
const GOLD = 'rgba(200, 176, 130, 1)';
const GRAYLIGHT = 'rgba(163, 163, 163, 1)';
const GRAY = 'rgba(128, 128, 128, 1)';
const GRAYMED = 'rgba(114, 115, 116, 1)';
const GRAYDARK = 'rgba(68, 69, 70, 1)';

const breakpoints = {
  // Nav Layout Change at Large
  // 555, 790, 990, 1200
  bpXSmall: '34.6875rem',
  bpSmall: '48.75rem',
  bpMedium: '61.875rem',
  bpLarge: '75rem',
};

const colors = {
  // global UI Colors
  colorBlack: 'rgba(0, 0, 0, 1)',
  colorWhite: 'rgba(255, 255, 255, 1)',

  colorPrimary: GOLD,
  colorSecondary: GRAYMED,
  colorTertiary: GRAYDARK,

  darkGray: GRAYDARK,
  UIHighlight: GOLD,
  colorGray: GRAYLIGHT,

  colorBGMain: 'rgba(252, 252, 252, 1)',
  colorBorder: 'rgba(246, 246, 246, 1)',
  colorBorderDark: GRAYLIGHT,

  // Status Colors
  colorAlert: 'rgba(221, 117, 117, 1)',
  colorWarning: 'rgba(235, 173, 105, 1)',

  // Icon Colors
  iconPrimary: 'rgba(0, 0, 0, 1)',
  iconSecondary: 'rgba(200, 176, 130, 1)',

  // Type Colors
  typeBody: GRAY,
  typeBodyLight: GRAYLIGHT,
  typeHeadlineDark: GRAYDARK,
  typeHeadlineLight: GRAY,
  typeButtonDark: GRAYMED,

  // Tables
  tableBorder: 'rgba(238, 238, 238, .65)',

  // Chart Colors
  colorChart1: 'rgb(129, 197, 199)',
  colorChart2: 'rgb(221, 117, 117)',
  colorChart3: 'rgb(152, 114, 214)',
  colorChart4: 'rgb(206, 207, 108)',
  colorChart5: 'rgb(96, 134, 231)',
  colorChart6: 'rgb(235, 173, 105)',
  colorChart7: 'rgb(133, 198, 78)',
  colorChart8: 'rgb(140, 140, 140)',
  colorChart9: 'rgb(146, 79, 3)',
  colorChart10: 'rgb(201, 80, 161)',
  colorChart11: 'rgb(201, 174, 80)',
  colorChart12: 'rgb(80, 187, 201)',
};

const fonts = {
  // font-families
  fontFamilyUI: "'IBM Plex Sans', sans-serif",
  fontFamilyData: "'IBM Plex Mono', monospace",

  // font weights
  fontWeightReg: '400',
  fontWeightItalic: '400i',
  fontWeightSemi: '600',
  fontWeightBold: '700',
};

const spacing = {
  gridGapSmall: '1.5rem',
  gridGapMedium: '2rem',
  gridGap: '3rem',

  contentGapSmall: '.875rem',
  contentGapMedium: '1rem',
  contentGap: '1.5rem',

  tablePad: '1.25rem',
};

const transitions = {
  circInOut: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
  cubicOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
};

const theme = {
  ...breakpoints,
  ...colors,
  ...fonts,
  ...spacing,
  ...transitions,
};

export default theme;
