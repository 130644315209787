import { combineReducers, Reducer } from 'redux';
import use from 'redux-package-loader';
import { WebState } from '@gerbil/types';
const corePackages = <any>[
  require('@gerbil/auth'),
  require('@gerbil/fetch'),
  require('@gerbil/forecast'),
  require('@gerbil/money'),
  require('@gerbil/invoice'),
  require('@gerbil/last-week'),
  require('@gerbil/delivery-metrics'),
  require('@gerbil/loading'),
];
const packages = use(corePackages);
const rootReducer: Reducer<WebState> = combineReducers(packages.reducers);
export { packages, rootReducer };
