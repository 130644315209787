import * as React from 'react';
import styled from 'styled-components';

interface TableProps {
  className?: string;
  children?: React.ReactNode;
}

const Table: React.SFC<TableProps> = ({ className, children }) => (
  <table className={className}>{children}</table>
);

const StyledTable = styled(Table)`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  border-radius: 0 0 0.25rem 0.25rem;
  border: 0.0625rem solid rgba(238, 238, 238, 1);
`;

export default StyledTable;
