import * as React from 'react';
import styled from 'styled-components';

import { VictoryChart, VictoryContainer, VictoryLine } from 'victory';

import ButtonRefresh from '../button-refresh';
import {
  ChartContainer,
  ChartFilter,
  ChartFilterButton,
  ChartHeader,
  ChartLayout,
  ChartLegend,
  ChartTheme,
  ChartWrap,
} from '../chart';
import Header from '../header';
import { LayoutData, LayoutPage } from '../layout';
import { Metric } from '../metric';
import { Tab, TabGroup } from '../tab-group';
import { Table, Thead, Th, Tbody, Tr } from '../table';

export default () => (
  <LayoutPage>
    <Header type="page" text="Page Title">
      <ButtonRefresh />
    </Header>

    <TabGroup>
      <Tab type="default" tabTitle="Tab">
        <LayoutData>
          <ChartLegend>
            <Metric type="default" label="Total" data="00000" />
            <Metric type="default" label="Value One" data="00000" />
            <Metric type="default" label="Value Two" data="00000" />
          </ChartLegend>

          <ChartLayout>
            <ChartHeader text="Active Tab Name and Identifier">
              <ChartFilter>
                <ChartFilterButton text="Filter One" type="active" />
                <ChartFilterButton text="Filter Two" type="inactive" />
              </ChartFilter>
            </ChartHeader>

            <ChartContainer>
              <ChartWrap>
                {(width) => (
                  <VictoryChart
                    containerComponent={<VictoryContainer responsive={false} />}
                    width={width}
                    height={600}
                    standalone={true}
                    theme={ChartTheme}
                  >
                    <VictoryLine />
                  </VictoryChart>
                )}
              </ChartWrap>
            </ChartContainer>
          </ChartLayout>

          <DataDetail>
            <Header
              type="section"
              color="light"
              text="Active Tab Name and Identifier"
            />
            <Table>
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th align="center">Forecasted</Th>
                  <Th align="center">Billed</Th>
                  <Th align="center">Difference</Th>
                </Tr>
              </Thead>
              <Tbody />
            </Table>
          </DataDetail>
        </LayoutData>
      </Tab>

      <Tab type="default" tabTitle="Tab" />
    </TabGroup>
  </LayoutPage>
);

const DataDetail = styled.section``;
