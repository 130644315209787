import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { WebState, Money, MoneyDollars } from '@gerbil/types';
import { selectors } from '@gerbil/money';

import {
  VictoryChart,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryLine,
  VictoryTooltip,
  VictoryLabel,
  VictoryContainer,
} from 'victory';

import { ChartTheme, ChartWrap } from '../chart';
import Header from '../header';
import Loader from '../loader';
import { Table, Thead, Tbody, Th, Tr, Td } from '../table';
import User from '../user';

const { getMoney } = selectors;

interface Props {
  data: Money;
}

interface IState {
  selectedDate: string;
}

interface ChartDatum {
  date: string;
  dollars: number;
}

const transformDataForChart = (d: MoneyDollars): ChartDatum[] =>
  Object.keys(d).map((date: string) => ({
    date,
    dollars: d[date],
  }));

interface ITableData {
  client: string;
  value: number;
}

const transformDataForTable = (date: string, d: Money): ITableData[] => {
  if (!date) {
    return [];
  }

  return Object.keys(d.dollars)
    .map((client: string) => ({
      client,
      value: d.dollars[client][date],
    }))
    .sort((a, b) => {
      return b.value - a.value;
    });
};

function formatDate(date: string) {
  const d = new Date(`${date}T00:00:00`);
  return `${d.getMonth() + 1} / ${d.getDate()}`;
}

function formatMoney(num: number) {
  return num > 999 ? `${(num / 1000).toFixed(0)}k` : num;
}

const thresholdStyle = {
  data: { strokeWidth: 1, stroke: 'tomato' },
  labels: { fontSize: 8, fill: 'tomato' },
};

const TableData = ({ date, data }: { date: string; data: ITableData[] }) => {
  if (!date) {
    return null;
  }

  const total = data.reduce((acc, row) => acc + row.value, 0);

  const TotalValue = styled.span`
    font-weight: ${(props) => props.theme.fontWeightSemi};
    color: ${(props) => props.theme.UIHighlight};
  `;

  return (
    <React.Fragment>
      <Header type="section" text={`Week of ${date}`}>
        <TotalValue>Total: ${total}</TotalValue>
      </Header>
      <Table>
        <Thead>
          <Tr>
            <Th align="left">Client</Th>
            <Th align="center">Dollars</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => {
            return (
              <Tr key={`table-${row.client}`}>
                <Td align="left">
                  <User name={row.client} />
                </Td>
                <Td align="center">{row.value}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </React.Fragment>
  );
};

export class MoneyForecastChart extends React.Component<Props, IState> {
  state = {
    selectedDate: '',
  };

  componentDidUpdate() {
    const { data } = this.props;
    const { selectedDate } = this.state;
    if (!selectedDate && data.dates.length > 0) {
      // override null default if we have data
      this.setState({ selectedDate: data.dates[0] });
    }
  }

  render() {
    const { data } = this.props;
    const { selectedDate } = this.state;
    if (data.dates.length === 0) {
      return <Loader />;
    }

    const dollars = Object.keys(data.dollars);
    const tableData = transformDataForTable(selectedDate, data);

    return (
      <React.Fragment>
        <Header type="section" color="light" text="Money Forecast By Week" />
        <ChartWrap>
          {(width) => (
            <VictoryChart
              containerComponent={<VictoryContainer responsive={false} />}
              width={width}
              height={600}
              standalone={true}
              theme={ChartTheme}
              animate={{ duration: 350, onLoad: { duration: 350 } }}
            >
              <VictoryAxis tickValues={data.dates} tickFormat={formatDate} />
              <VictoryAxis
                dependentAxis
                domain={[0, 103000]}
                tickFormat={formatMoney}
              />
              <VictoryStack
                events={[
                  {
                    childName: 'all',
                    target: 'data',
                    eventHandlers: {
                      onClick: (e: any, props: any): any => {
                        e.preventDefault();
                        this.setState({ selectedDate: props.datum.date });
                        return [];
                      },
                    },
                  },
                ]}
              >
                {dollars.map((d: string) => {
                  const chartData = transformDataForChart(data.dollars[d]);
                  return (
                    <VictoryBar
                      key={d}
                      data={chartData}
                      x="date"
                      y="dollars"
                      labels={(a: { dollars: number }) => {
                        return `${d}\n$${a.dollars}`;
                      }}
                      labelComponent={
                        <VictoryTooltip
                          dy={-40}
                          cornerRadius={2}
                          pointerLength={2}
                          flyoutStyle={{
                            fill: 'rgba(255, 255, 255, .9)',
                            stroke: 'rgb(238, 238, 238)',
                          }}
                        />
                      }
                    />
                  );
                })}
              </VictoryStack>
              <VictoryLine
                y={() => 170000}
                samples={1}
                style={thresholdStyle}
                labels={['', 'target']}
                labelComponent={<VictoryLabel renderInPortal dx={23} dy={11} />}
              />
            </VictoryChart>
          )}
        </ChartWrap>

        <TableData date={selectedDate} data={tableData} />
      </React.Fragment>
    );
  }
}

const mapState = (state: WebState) => ({
  data: getMoney(state),
});
export default connect(mapState)(MoneyForecastChart);
