import robodux from '@gerbil/robodux';
import { LastWeekState } from '@gerbil/types';

const slice = 'lastWeekHours';

const { actions, reducer } = robodux<LastWeekState>({
  actions: {
    setLastWeekHours: (state: LastWeekState, payload: LastWeekState) => payload,
  },
  initialState: {},
  slice,
});

const reducers = {
  [slice]: reducer,
};

export { actions, reducers, slice };
