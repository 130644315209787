import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';

import { effects } from '@gerbil/auth';
const { logout } = effects;

import Icon from '../icon';

interface IButtonLogoutProps {
  className?: string;
}

interface IDispatch {
  onClick: () => void;
}

interface IProps extends IButtonLogoutProps, IDispatch {}

export const ButtonLogout = ({ className, onClick }: IProps) => (
  <button className={className} onClick={() => onClick()}>
    <Text>Logout</Text>
    <IconWrap>
      <Icon icon="UILogout" color="gold" />
    </IconWrap>
  </button>
);

const StyledButtonLogout = styled(ButtonLogout)`
  appearance: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 0;
  padding: 0 0;
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
`;

const Text = styled.span`
  display: flex;
  position: relative;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.058rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.darkGray};
  opacity: 0.75;
`;

const IconWrap = styled.span`
  display: flex;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0 0 0.5rem;
  padding: 0 0;
`;

export const mapDispatch = (dispatch: Dispatch) => ({
  onClick: () => dispatch(logout()),
});

export default connect<null, IDispatch>(
  null,
  mapDispatch,
)(StyledButtonLogout);
