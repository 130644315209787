import * as React from 'react';
import styled from 'styled-components';

import ButtonLogin from '../button-login';
import Icon from '../icon';
import { LayoutPage } from '../layout';

const Content = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
`;

const IconGerbil = styled.span`
  display: flex;
  width: 5.75rem;
  height: 6.125rem;
  margin: 0 0 1.25rem;
  padding: 0 0;
  cursor: default;
`;

export default () => (
  <LayoutPage authenticated={false}>
    <Content>
      <IconGerbil>
        <Icon icon="logoGerbil" color="gold" />
      </IconGerbil>
      <ButtonLogin />
    </Content>
  </LayoutPage>
);
