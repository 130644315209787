import * as React from 'react';

import styled from 'styled-components';
import { defaultTheme as theme } from '@gerbil/theme';

interface MetricProps {
  type: 'default' | 'mini';
  className?: string;
  label: string;
  data: any;
}

const Metric: React.SFC<MetricProps> = ({ className, label, data }) => (
  <div className={className}>
    <Label>{label}</Label>
    <Data>{data}</Data>
  </div>
);

const StyledMetric = styled(Metric)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => (props.type === 'mini' ? 'auto' : '100%')};
  height: auto;
  margin: 0 0;
  padding: 0 0;
  font-size: ${(props) => (props.type === 'mini' ? '1.25rem' : '1.875rem')};
  text-align: ${(props) => (props.type === 'mini' ? 'center' : 'left')};
  color: ${(props) =>
    props.type === 'mini'
      ? (props) => props.theme.typeBodyLight
      : (props) => props.theme.typeHeadlineDark};

  @media (min-width: ${(props) => props.theme.bpSmall}) {
    align-items: ${(props) =>
      props.type === 'mini' ? 'center' : 'flex-start'};
  }

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    font-size: ${(props) => (props.type === 'mini' ? '1.5rem' : '2.25rem')};
  }
`;

const Label = styled.label`
  display: flex;
  width: auto;
  height: auto;
  margin: 0 0 0.5rem;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 0.625rem;
  line-height: 100%;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  text-align: inherit;
  @media (min-width: ${(props) => props.theme.bpMedium}) {
    font-size: 0.75rem;
  }
`;

const Data = styled.span`
  display: flex;
  width: auto;
  height: auto;
  font-family: ${theme.fontFamilyUI};
  font-weight: ${theme.fontWeightBold};
  line-height: 100%;
  letter-spacing: 0;
  text-transform: uppercase;
  text-align: inherit;
  color: ${theme.darkGray};
`;

export default StyledMetric;
