import * as React from 'react';
import styled from 'styled-components';

import Icon from '../icon';

interface INavUtilityProps {
  className?: string;
  children?: React.ReactNode;
  toggleNav?: () => void;
}

class NavUtility extends React.Component<INavUtilityProps> {
  render() {
    const { className, children, toggleNav } = this.props;
    return (
      <section className={className}>
        <IconNav onClick={toggleNav}>
          <Icon icon="UINav" color="gold" />
        </IconNav>
        {children}
      </section>
    );
  }
}

const StyledNavUtility = styled(NavUtility)`
  grid-area: utility;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.25rem;
  margin: 0 0;
  padding: 0 ${(props) => props.theme.gridGapSmall};
  background: ${(props) => props.theme.colorBGMain};
  border-bottom: 0.0625rem solid ${(props) => props.theme.colorBorder};
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    padding: 0 ${(props) => props.theme.gridGapMedium};
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    justify-content: flex-end;
    padding: 0 ${(props) => props.theme.gridGap};
  }
`;

const IconNav = styled.span`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    display: none;
  }
`;

export default StyledNavUtility;
