import * as React from 'react';
import styled from 'styled-components';

interface CardContainerProps {
  className?: string;
  children?: React.ReactNode;
}

const CardContainer: React.SFC<CardContainerProps> = ({
  className,
  children,
}) => <section className={className}>{children}</section>;

const StyledCardContainer = styled(CardContainer)`
  display: grid;
  grid-gap: ${(props) => props.theme.contentGapSmall};
  grid-template-columns: 100%;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;

  @media (min-width: ${(props) => props.theme.bpSmall}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    grid-gap: ${(props) => props.theme.contentGapMedium};
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    grid-gap: ${(props) => props.theme.contentGap};
  }
`;

export default StyledCardContainer;
