import * as debug from 'debug';
import { select, put, call, createEffects } from 'redux-cofx';
import Auth0 from 'auth0-js';
import { push } from 'connected-react-router';

import { actions, selectors } from './slice';
const {
  setToken,
  resetToken,
  setTokenExpiration,
  resetTokenExpiration,
} = actions;
const { getToken /* , getHasTokenExpired */ } = selectors;
import { createWebAuth, parseHash, removeHash, checkSession } from './utils';

const log = debug('app:auth:effects');

export function* onAuthenticate(options: Auth0.AuthOptions) {
  log('Attempting to authenticate user ...');
  const auth0 = yield call(createWebAuth, options);
  const authorizeOpts = {
    audience: 'https://webgerbil.com',
    grantType: 'client_credentials',
  };
  yield call([auth0, 'authorize', authorizeOpts]);
}

interface ICheckAuth {
  options: Auth0.AuthOptions;
  hash: string;
}

export function* onCheckAuth({ options, hash }: ICheckAuth) {
  log('Atempting to check authentication ...');
  const token = yield select(getToken);
  if (token) {
    log('found token, exiting early');
    return;
  }

  const auth0 = yield call(createWebAuth, { ...options });
  try {
    const opts = {
      auth0,
      hash,
    };
    const authResult: Auth0.Auth0DecodedHash = yield call(parseHash, opts);
    log(authResult);

    if (!authResult) {
      return;
    }
    const token = authResult.accessToken;
    const tokenExpiration = {
      expiresIn: authResult.expiresIn,
      timestamp: new Date().getTime() / 1000,
    };
    yield put(setToken(token));
    yield put(setTokenExpiration(tokenExpiration));
    yield call(removeHash);
    return token;
  } catch (err) {
    log(err);
  }
}

export function* onRefreshToken(options: Auth0.AuthOptions) {
  log('Atempting to refresh token ...');
  /* const hasTokenExpired = yield select(getHasTokenExpired);
  if (!hasTokenExpired) {
    log('token has not expired, exiting early');
    return;
  } */

  const auth0 = yield call(createWebAuth, { ...options });
  try {
    const authResult: Auth0.Auth0DecodedHash = yield call(checkSession, {
      auth0,
      options: {
        audience: 'https://webgerbil.com',
      },
    });
    log(authResult);

    if (!authResult) {
      return;
    }

    const token = authResult.accessToken;
    const tokenExpiration = {
      expiresIn: authResult.expiresIn,
      timestamp: new Date().getTime() / 1000,
    };
    yield put(setToken(token));
    yield put(setTokenExpiration(tokenExpiration));
  } catch (err) {
    log(err);
  }
}

export function* onLogout() {
  yield put(resetToken());
  yield put(resetTokenExpiration());
  yield put(push('/login'));
}

const { auth, checkAuth, logout, refreshToken } = createEffects({
  auth: onAuthenticate,
  checkAuth: onCheckAuth,
  logout: onLogout,
  refreshToken: onRefreshToken,
});

const effects = {
  auth,
  checkAuth,
  logout,
  onCheckAuth,
  refreshToken,
  onRefreshToken,
};
export default effects;
