import * as debug from 'debug';

import robodux from '@gerbil/robodux';
import {
  AuthInfoState,
  TokenState,
  WebState,
  TokenExpiration,
} from '@gerbil/types';

const log = debug('app:auth:slice');
const defaultAuthInfoState = {
  domain: '',
  redirectUri: '',
  clientID: '',
};
const authInfoSlice = 'authInfo';
const authInfo = robodux<AuthInfoState>({
  slice: authInfoSlice,
  initialState: defaultAuthInfoState,
  actions: {
    setAuthInfo: (state: AuthInfoState, payload: AuthInfoState) => payload,
  },
});

const tokenSlice = 'token';
const token = robodux<string>({
  slice: tokenSlice,
  initialState: '',
  actions: {
    setToken: (state: TokenState, payload: TokenState) => payload,
    resetToken: () => '',
  },
});

const defaultTokenExpiration: TokenExpiration = {
  expiresIn: '',
  timestamp: '',
};
const tokenExpirationSlice = 'tokenExpiration';
const tokenExpiration = robodux<TokenExpiration>({
  slice: tokenExpirationSlice,
  initialState: defaultTokenExpiration,
  actions: {
    setTokenExpiration: (state, payload) => payload,
    resetTokenExpiration: (state) => defaultTokenExpiration,
  },
});

const selectors: { [key: string]: any } = {
  getAuthInfo: (state: WebState) => state[authInfoSlice],
  getToken: (state: WebState) => state[tokenSlice],
  getTokenExpiration: (state: WebState) => state[tokenExpirationSlice],
};
selectors.getIsAuthenticated = (state: WebState) => !!selectors.getToken(state);
selectors.getHasTokenExpired = (state: WebState) => {
  const expiration = selectors.getTokenExpiration(state);
  const now = new Date().getTime() / 1000;
  if (!expiration.timestamp || !expiration.expiresIn) {
    log('Token expiration not found');
    return false;
  }
  const expired = expiration.timestamp + expiration.expiresIn;
  log(`token will expire ${expired}, currently ${now}`);
  return expired <= now;
};

const reducers = {
  [authInfoSlice]: authInfo.reducer,
  [tokenSlice]: token.reducer,
  [tokenExpirationSlice]: tokenExpiration.reducer,
};

const actions = {
  ...authInfo.actions,
  ...token.actions,
  ...tokenExpiration.actions,
};

export { actions, reducers, selectors };
