import * as React from 'react';
import { connect } from 'react-redux';

import { WebState, InvoiceState, InvoiceData } from '@gerbil/types';
import { selectors } from '@gerbil/invoice';
const { getInvoice } = selectors;

import ButtonRefresh from '../button-refresh';
import { CardContainer } from '../card';
import CardInvoiceCheck from '../card-invoice-check';
import Header from '../header';
import { LayoutPage } from '../layout';
import Loader from '../loader';

interface Props {
  data: InvoiceState;
}

const Card = ({
  clientName,
  workWeek,
  invoiced,
  forecasted,
  delta,
}: InvoiceData) => (
  <CardInvoiceCheck
    clientName={clientName}
    workWeek={workWeek}
    forecasted={forecasted}
    invoiced={invoiced}
    delta={delta}
  />
);

export const Invoice: React.SFC<Props> = ({ data }) => {
  const clients = Object.keys(data);

  return (
    <LayoutPage>
      <Header type="page" text="Invoice Check">
        <ButtonRefresh />
      </Header>

      {clients.length === 0 && <Loader />}

      <CardContainer>
        {clients.map((client: string) => (
          <Card key={client} {...data[client]} />
        ))}
      </CardContainer>
    </LayoutPage>
  );
};

const mapState = (state: WebState) => ({
  data: getInvoice(state),
});

export default connect(mapState)(Invoice);
