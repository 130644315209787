import * as React from 'react';
import styled from 'styled-components';

interface IChartLegendProps {
  className?: string;
  children?: React.ReactNode;
}

const ChartLegend: React.SFC<IChartLegendProps> = ({ className, children }) => (
  <section className={className}>{children}</section>
);

const StyledChartLegend = styled(ChartLegend)`
  display: grid;
  grid-gap: ${(props) => props.theme.gridGapSmall};
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;

  @media (min-width: ${(props) => props.theme.bpXSmall}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${(props) => props.theme.bpSmall}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    grid-gap: ${(props) => props.theme.gridGapMedium};
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export default StyledChartLegend;
