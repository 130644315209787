import * as React from 'react';
import styled from 'styled-components';

interface ITabNavProps {
  children?: React.ReactNode;
  className?: string;
}

const TabNav: React.SFC<ITabNavProps> = ({ children, className }) => (
  <nav className={className} role="navigation" aria-label="Tab Navigation">
    {children}
  </nav>
);

const StyledTabNav = styled(TabNav)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 2rem;
  padding: 0 0;
  border-bottom: 0.125rem solid rgba(249, 249, 249, 1);
`;

export default StyledTabNav;
