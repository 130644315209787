import * as React from 'react';
import styled from 'styled-components';

import icons, { ISVGElement } from './icons';

interface IIconProps {
  icon: string;
  color: 'gold' | 'gray' | 'white';
  className?: string;
}

export const Icon: React.SFC<IIconProps> = ({
  icon,
  color = 'gold',
  className,
}) => {
  const haveSVG = icons.hasOwnProperty(icon);
  const svg: ISVGElement = haveSVG ? icons[icon] : null;
  if (!svg) {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `Could not find the icon ${icon}, returning null for Icon component`,
      );
    }
    return null;
  }

  return (
    <svg className={className} viewBox={svg.viewbox}>
      <title>{svg.title}</title>
      {svg.paths}
    </svg>
  );
};

export const getFillColor = (props: any) => {
  if (props.color === 'gold') {
    return props.theme.colorPrimary;
  }
  if (props.color === 'gray') {
    return props.theme.colorSecondary;
  }
  if (props.color === 'white') {
    return props.theme.colorWhite;
  }
  return props.theme.colorPrimary;
};

const StyledIcon = styled(Icon)`
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;
  fill: ${(props) => getFillColor(props)};
`;

export default StyledIcon;
