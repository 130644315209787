import * as React from 'react';

import Header from '../header';
import { LayoutPage } from '../layout';
import MoneyChart from '../chart-money';
import ButtonRefresh from '../button-refresh';

export default () => (
  <LayoutPage>
    <Header type="page" text="Money Forecast">
      <ButtonRefresh />
    </Header>
    <MoneyChart />
  </LayoutPage>
);
