import * as React from 'react';
import styled from 'styled-components';

interface TdProps {
  align?: 'left' | 'center';
  className?: string;
  children?: React.ReactNode;
}

const Td: React.SFC<TdProps> = ({ align = 'left', className, children }) => (
  <td className={className}>{children}</td>
);

const StyledTd = styled(Td)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${(props) =>
    props.align === 'center' ? 'center' : 'flex-start'};
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 1.75rem ${(props) => props.theme.tablePad} 1.5rem;
  position: relative;
  font-family: ${(props) => props.theme.fontFamilyData};
  font-weight: ${(props) => props.theme.fontWeightReg};
  font-size: 0.875rem;
  line-height: 100%;
  color: ${(props) => props.theme.typeBody};
`;

export default StyledTd;
