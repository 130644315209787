import * as React from 'react';
import styled from 'styled-components';

import Icon from '../icon';

interface ButtonProps {
  type: 'primary' | 'secondary' | 'inline';
  className?: string;
  children?: React.ReactNode;
  icon?: string;
  onClick?: () => void;
}

const Button: React.SFC<ButtonProps> = ({
  type = 'primary',
  className,
  children,
  icon,
  onClick,
}) => (
  <button className={className} onClick={onClick}>
    {icon ? (
      <IconWrap>
        <Icon icon={icon} color="gold" />
      </IconWrap>
    ) : null}
    <Text>{children}</Text>
  </button>
);

const StyledButton = styled(Button)`
  appearance: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: ${(props) => (props.type === 'inline' ? 'auto' : '2.375rem')};
  margin: 0 0;
  padding: ${(props) => (props.type === 'inline' ? '0 0' : '0 1.5rem')};
  position: relative;
  border-radius: 0.125rem;
  border: ${(props) => (props.type === 'inline' ? 'none' : '0.0625rem solid')};
  border-color: ${(props) =>
    props.type === 'secondary'
      ? (props) => props.theme.colorSecondary
      : (props) => props.theme.colorPrimary};
  outline: none;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.058rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.darkGray};
  cursor: pointer;
`;

const IconWrap = styled.span`
  display: flex;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.5rem 0 0;
  padding: 0 0;
`;

const Text = styled.span`
  display: flex;
  position: relative;
  top: 0.0625rem;
  opacity: 0.75;
`;

export default StyledButton;
