import * as React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';

import { defaultTheme } from '@gerbil/theme';
import { effects } from '@gerbil/bootup';
const { bootup } = effects;

import createStore from './store';
import { rootReducer, packages } from './packages';
import App from './app';

interface WindowInterface extends Window {
  reduxStore: any;
  packages: any;
}

export default () => {
  const { store, history, persistor } = createStore({
    rootReducer,
  });
  (window as WindowInterface).reduxStore = store;
  (window as WindowInterface).packages = packages;

  store.dispatch(bootup());

  render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>,
    document.querySelector('#app'),
  );
};
