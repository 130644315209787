import * as React from 'react';
import styled from 'styled-components';

interface CardWrapProps {
  className?: string;
  children?: React.ReactNode;
}

const CardWrap: React.SFC<CardWrapProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
);

const StyledCardWrap = styled(CardWrap)`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 1rem 1rem 1.125rem 1rem;
  background: transparent;
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(238, 238, 238, 1);

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    padding: 2rem 2rem 2.125rem 2rem;
  }
`;

export default StyledCardWrap;
