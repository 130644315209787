import { call, put, createEffect } from 'redux-cofx';

import apiFetch from '@gerbil/fetch';

import { actions } from './slice';
import { LastWeek, LastWeekData } from '@gerbil/types';


interface LastWeekResponse {
  users: {
    email: { [key: string]: string };
    real_name: { [key: string]: string };
    billable: { [key: string]: boolean };
    avatar_url: { [key: string]: string };
  },
  report: {
    client_name: { [key: string]: string };
    email: { [key: string]: string };
    harvest_project_id: { [key: string]: number };
    hours: { [key: string]: number };
    name: { [key: string]: string };
  },
}

function transformLastWeek(response: LastWeekResponse): LastWeek {
  // get unique emails out of list
  const report = response.report;
  const users = response.users;

  return Object.keys(users.email).reduce(
    (acc: { [key: string]: LastWeekData }, email: string) => {
      const indexes: string[] = Object.keys(report.email).reduce(
        (accumulator: string[], index: string) => {
          return report.email[index] === email
            ? accumulator.concat([index])
            : accumulator;
        },
        [],
      );

      acc[email] = {
        avatarUrl: users.avatar_url[email],
        entryName: indexes.map((idx: string) => `${report.client_name[idx]}: ${report.name[idx]}`),
        harvestProjectIds: indexes.map((idx) => report.harvest_project_id[idx]),
        hours: indexes.map((idx: string) => report.hours[idx]),
        realName: users.real_name[email],
        total: indexes.reduce((acc, idx) => acc + report.hours[idx], 0.0),
      };
      return acc;
    },
    {},
  );
}

export function* onFetchLastWeekHours() {
  const res = yield call(apiFetch, '/report_last_weeks_entries_summary');
  if (res.status < 200 || res.status >= 300) {
    // do something with error
    return;
  }

  const data = transformLastWeek(res.body);
  yield put(actions.setLastWeekHours(data));
}

const fetchLastWeekHours = () => createEffect(onFetchLastWeekHours);

export const effects = { fetchLastWeekHours, onFetchLastWeekHours };
