import * as React from 'react';
import styled from 'styled-components';

import { CardWrap } from '../card';
import { H3 } from '../typography';
import { Metric, MetricDelta } from '../metric';

interface CardInvoiceCheckProps {
  clientName?: string;
  workWeek?: number;
  forecasted?: number;
  invoiced?: number;
  delta?: number;
}

const CardInvoiceCheck: React.SFC<CardInvoiceCheckProps> = ({
  clientName,
  workWeek,
  invoiced,
  forecasted,
  delta,
}) => (
  <CardWrap>
    <InvoiceHeader>
      <InvoiceClient>{clientName}</InvoiceClient>
      <InvoiceDate>{workWeek}</InvoiceDate>
    </InvoiceHeader>

    <InvoiceNumbers>
      <Metric type="mini" label="Forecasted" data={forecasted} />
      <Metric type="mini" label="Invoiced" data={invoiced} />
      <MetricDelta label="Difference" data={delta} />
    </InvoiceNumbers>
  </CardWrap>
);

const InvoiceHeader = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  height: auto;
  margin: 0 0 2rem;
  padding: 0 0;
`;

const InvoiceClient = styled(H3)`
  display: flex;
  width: auto;
  margin: 0 0 0 0;
`;

const InvoiceDate = styled.span`
  display: flex;
  width: auto;
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 0.75rem;
  line-height: 100%;
  letter-spacing: 0.171em;
  color: ${(props) => props.theme.typeBodyLight};
`;

const InvoiceNumbers = styled.section`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: auto;
`;

export default CardInvoiceCheck;
