import { take, put, createEffect, call, all } from 'redux-cofx';

import { actions, effects as authEffects } from '@gerbil/auth';
const { setAuthInfo } = actions;
const { onRefreshToken } = authEffects;
import { actions as fetchActions } from '@gerbil/fetch';
const { setApiEndpoint } = fetchActions;
import { effects as availEffects } from '@gerbil/forecast';
const { onFetchForecast } = availEffects;
import { effects as moneyEffects } from '@gerbil/money';
const { onFetchMoney } = moneyEffects;
import { effects as invoiceEffects } from '@gerbil/invoice';
const { onFetchInvoice } = invoiceEffects;
import { effects as lastWeekEffects } from '@gerbil/last-week';
const { onFetchLastWeekHours } = lastWeekEffects;
import { effects as metricsEffects } from '@gerbil/delivery-metrics';
const { onFetchDeliveryMetrics } = metricsEffects;
import { actions as loadingActions } from '@gerbil/loading';
import { REHYDRATE } from 'redux-persist';
const { setLoading } = loadingActions;

export function* onBootup() {
  const redirectUri =
    process.env.NODE_ENV === 'production'
      ? 'http://webgerbil.com'
      : 'http://localhost:8000';
  const domain = process.env.AUTH0_DOMAIN || '';
  const clientID = process.env.AUTH0_CLIENT_ID || '';
  const authOpts = {
    domain,
    clientID,
    redirectUri,
    responseType: 'token id_token',
  };
  const apiEndpoint = process.env.API_ENDPOINT || '';

  yield put(setApiEndpoint(apiEndpoint));
  yield put(setAuthInfo(authOpts));

  yield take(REHYDRATE);
  yield call(onRefreshToken, authOpts);
  yield call(onFetchData);
}

export function* onFetchData() {
  yield put(setLoading({ loading: true }));
  yield all([
    call(onFetchForecast),
    call(onFetchMoney),
    call(onFetchInvoice),
    call(onFetchLastWeekHours),
    call(onFetchDeliveryMetrics),
  ]);
  yield put(setLoading({ loading: false }));
}

const bootup = () => createEffect(onBootup);
const fetchData = () => createEffect(onFetchData);

const effects = {
  bootup,
  fetchData,
};

export { effects };
