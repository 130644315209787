import * as React from 'react';
import {
  VictoryChart,
  VictoryContainer,
  VictoryStack,
  VictoryBar,
  VictoryAxis,
  VictoryLegend,
  VictoryLine,
} from 'victory';
import { connect } from 'react-redux';

import { WebState } from '@gerbil/types';
import { selectors, ForecastChartData, ChartData } from '@gerbil/forecast';
const { getForecastBookedRatio } = selectors;

import {
  ChartContainer,
  ChartFilter,
  ChartFilterButton,
  ChartHeader,
  ChartLayout,
  ChartTheme,
  ChartWrap,
} from '../chart';
import Loader from '../loader';

interface Data extends ForecastChartData {
  [key: string]: ChartData;
}

interface Props {
  data: Data;
}

type ForecastType = 'billable' | 'designers' | 'engineers' | 'productManagers';

interface State {
  selected: ForecastType;
}

const thresholdStyle = {
  data: { strokeWidth: 1 },
};

const axisStyle = { axisLabel: { padding: 32 } };

const isSelected = (selected: ForecastType, cur: ForecastType) => {
  return selected === cur;
};

const getButtonColor = (selected: ForecastType) => (cur: ForecastType) => {
  return isSelected(selected, cur) ? 'active' : 'inactive';
};

interface Readable {
  [index: string]: string;
}

const readable: Readable = {
  billable: 'Billable',
  designers: 'Designers',
  engineers: 'Engineers',
  productManagers: 'Product Managers',
};

const ChartButtonFilter = ({
  btnColor,
  name,
  onClick,
}: {
  btnColor: (cur: ForecastType) => 'active' | 'inactive';
  name: ForecastType;
  onClick: (name: ForecastType) => void;
}) => (
  <ChartFilterButton
    text={readable[name]}
    type={btnColor(name)}
    onClick={() => onClick(name)}
  />
);

function formatDate(date: string) {
  const d = new Date(`${date}T00:00:00`);
  return `${d.getMonth() + 1} / ${d.getDate()}`;
}

export class ForecastChart extends React.Component<Props, State> {
  state = {
    selected: 'billable' as ForecastType,
  };

  setSelected = (selected: ForecastType) => {
    this.setState({ selected });
  };

  render() {
    const { data } = this.props;
    const { selected } = this.state;
    const { dates, booked, open } = data[selected];
    const btnColor = getButtonColor(selected);

    if (dates.length === 0) {
      return <Loader />;
    }

    return (
      <ChartLayout>
        <ChartHeader text="Resource Allocation">
          <ChartFilter>
            <ChartButtonFilter
              name="billable"
              onClick={this.setSelected}
              btnColor={btnColor}
            />
            <ChartButtonFilter
              name="designers"
              onClick={this.setSelected}
              btnColor={btnColor}
            />
            <ChartButtonFilter
              name="engineers"
              onClick={this.setSelected}
              btnColor={btnColor}
            />
            <ChartButtonFilter
              name="productManagers"
              onClick={this.setSelected}
              btnColor={btnColor}
            />
          </ChartFilter>
        </ChartHeader>

        <ChartContainer>
          <ChartWrap>
            {(width) => (
              <VictoryChart
                containerComponent={<VictoryContainer responsive={false} />}
                width={width}
                height={600}
                standalone={true}
                theme={ChartTheme}
              >
                <VictoryLegend
                  x={0}
                  y={0}
                  orientation="horizontal"
                  data={[{ name: 'Booked' }, { name: 'Open' }]}
                />
                <VictoryAxis
                  tickValues={dates}
                  tickFormat={formatDate}
                  style={axisStyle}
                />
                <VictoryAxis dependentAxis style={axisStyle} />
                <VictoryStack
                  animate={{ duration: 350, onLoad: { duration: 350 } }}
                >
                  <VictoryBar data={booked} x="date" y="hours" />
                  <VictoryBar data={open} x="date" y="hours" />
                </VictoryStack>
                <VictoryLine y={() => 70} samples={1} style={thresholdStyle} />
                <VictoryLine y={() => 80} samples={1} style={thresholdStyle} />
                <VictoryLine y={() => 90} samples={1} style={thresholdStyle} />
              </VictoryChart>
            )}
          </ChartWrap>
        </ChartContainer>
      </ChartLayout>
    );
  }
}

const mapState = (state: WebState) => ({
  data: getForecastBookedRatio(state),
});
export default connect(mapState)(ForecastChart);
