import * as React from 'react';
import { connect } from 'react-redux';

import {
  WebState,
  DeliveryMetrics as DeliveryMetricsType,
} from '@gerbil/types';
import { selectors } from '@gerbil/delivery-metrics';
const { getDeliveryMetrics } = selectors;

import ButtonRefresh from '../button-refresh';
import ChartBillRate from '../chart-bill-rate';
import Header from '../header';
import { LayoutPage } from '../layout';
import { Tab, TabGroup } from '../tab-group';

interface MapState {
  data: DeliveryMetricsType;
}

type IProps = MapState;

export const DeliveryMetrics: React.SFC<IProps> = ({ data }) => {
  return (
    <LayoutPage>
      <Header type="page" text="Delivery Metrics">
        <ButtonRefresh />
      </Header>

      <TabGroup>
        <Tab type="chart" tabTitle="Bill Rate">
          <ChartBillRate
            title="Monthly Bill Rate"
            yLabel="Rate (usd/hr)"
            data={data.averageBillRateByMonth}
          />
        </Tab>

        <Tab type="chart" tabTitle="Friday Investments">
          <ChartBillRate
            title="Friday Investment Hours"
            data={data.investmentOnFriday}
          />
        </Tab>

        <Tab type="chart" tabTitle="Hours Underbilled">
          <ChartBillRate
            title="Hours Underbilled"
            data={data.hoursUnderbilled}
          />
        </Tab>

        <Tab type="chart" tabTitle="Hours Billed on Friday">
          <ChartBillRate
            title="Hours Billed on Friday"
            data={data.billedOnFriday}
          />
        </Tab>
      </TabGroup>
    </LayoutPage>
  );
};

const mapState = (state: WebState) => ({
  data: getDeliveryMetrics(state),
});

export default connect<MapState>(mapState)(DeliveryMetrics);
