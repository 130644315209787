import * as React from 'react';
import styled from 'styled-components';

interface EmptyStateProps {
  text?: string;
  className?: string;
}

const EmptyState: React.SFC<EmptyStateProps> = ({
  text = 'Feed The Gerbil',
  className,
}) => (
  <section className={className}>
    <Text>{text}</Text>
  </section>
);

const StyledEmptyState = styled(EmptyState)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: ${(props) => props.theme.gridGap};
  text-align: center;
`;

const Text = styled.span`
  width: 100%;
  height: auto;
  font-family: ${(props) => props.theme.fontFamilyData};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: rgba(212, 212, 212, 1);
`;

export default StyledEmptyState;
