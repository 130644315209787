import * as React from 'react';
import styled from 'styled-components';

import EmptyState from '../empty-state';

interface ChartContainerProps {
  className?: string;
  children?: React.ReactNode;
}

const ChartContainer: React.SFC<ChartContainerProps> = ({
  className,
  children,
}) => (
  <div className={className}>
    {!children && <EmptyState text="Feed Me Numbers!" />}
    {children}
  </div>
);

const StyledChartContainer = styled(ChartContainer)`
  grid-area: Chart;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
`;

export default StyledChartContainer;
