import { WebAuth, AuthOptions } from 'auth0-js';

export const createWebAuth = (options: AuthOptions) =>
  new WebAuth({ ...options });

interface IParseHash {
  auth0: WebAuth;
  hash: string;
}

export const parseHash = ({ auth0, hash }: IParseHash) => {
  return new Promise((resolve, reject) => {
    auth0.parseHash({ hash }, (err, authResult) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(authResult);
    });
  });
};

export function removeHash() {
  history.pushState(
    '',
    document.title,
    window.location.pathname + window.location.search,
  );
}

interface ICheckSession {
  auth0: WebAuth;
  options: any;
}

export function checkSession({ auth0, options }: ICheckSession) {
  return new Promise((resolve, reject) => {
    auth0.checkSession(options, (err, authResult) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(authResult);
    });
  });
}
