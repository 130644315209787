import * as React from 'react';
import styled from 'styled-components';

interface ITabProps {
  tabTitle: string;
  type: 'default' | 'chart';
  children?: React.ReactNode;
  className?: string;
}

const Tab: React.SFC<ITabProps> = ({
  type = 'default',
  children,
  className,
}) => <section className={className}>{children}</section>;

const StyledTab = styled(Tab)`
  width: 100%;
  height: auto;
  margin: ${(props) =>
    props.type === 'chart' ? (props) => '2rem 0 0' : (props) => '0 0'};
`;

export default StyledTab;
