import * as React from 'react';

import { Route, Switch, withRouter } from 'react-router';

import {
  LoginPage,
  ForecastPage,
  MoneyPage,
  InvoicePage,
  LastWeekHoursPage,
  DeliveryMetricsPage,
  TemplatePage,
} from '@gerbil/ui';
import { routes } from '@gerbil/router';

export const App = () => {
  return (
    <Switch>
      <Route exact path={routes.INDEX} component={ForecastPage} />
      <Route exact path={routes.LOGIN} component={LoginPage} />
      <Route exact path={routes.MONEY} component={MoneyPage} />
      <Route exact path={routes.INVOICE} component={InvoicePage} />
      <Route
        exact
        path={routes.DELIVERY_METRICS}
        component={DeliveryMetricsPage}
      />
      <Route
        exact
        path={routes.LAST_WEEK_HOURS}
        component={LastWeekHoursPage}
      />
      <Route exact path={routes.PAGE_TEMPLATE} component={TemplatePage} />
    </Switch>
  );
};

export default withRouter(App);
