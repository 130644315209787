import * as React from 'react';
import styled from 'styled-components';

interface IChartbuttonProps {
  type?: 'active' | 'inactive';
  text?: string;
  onClick?: () => void;
  className?: string;
}

const ChartFilterButton: React.SFC<IChartbuttonProps> = ({
  type = 'inactive',
  text,
  onClick,
  className,
}) => (
  <span className={className} onClick={onClick}>
    {text}
  </span>
);

const StyledChartButton = styled(ChartFilterButton)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 3.375rem;
  height: 1.4375rem;
  margin: 0 1.5rem 0 0;
  padding: 0 0;
  border-bottom: 0.125rem solid;
  border-color: ${(props) =>
    props.type === 'active'
      ? (props) => props.theme.colorPrimary
      : (props) => 'transparent'};
  font-size: 0.875rem;
  line-height: 100%;
  color: ${(props) =>
    props.type === 'active'
      ? (props) => props.theme.colorPrimary
      : (props) => props.theme.colorGray};
  cursor: pointer;

  &:last-child {
    margin: 0 0 0 0;
  }
`;

export default StyledChartButton;
