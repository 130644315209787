import * as React from 'react';
import styled from 'styled-components';

interface TbodyProps {
  className?: string;
  children?: React.ReactNode;
}

const Tbody: React.SFC<TbodyProps> = ({ className, children }) => (
  <tbody className={className}>{children}</tbody>
);

const StyledTbody = styled(Tbody)`
  display: table-row-group;
  width: 100%;
  height: auto;
`;

export default StyledTbody;
