import robodux from '@gerbil/robodux';
import { Loading, WebState } from '@gerbil/types';

const slice = 'loading';

const { actions, reducer } = robodux<Loading>({
  actions: {
    setLoading: (state: Loading, payload: Loading) => payload,
  },
  initialState: { loading: false },
  slice,
});

const reducers = {
  [slice]: reducer,
};

const getLoading = (state: WebState) => state[slice];
const getIsLoading = (state: WebState) => {
  const loading = getLoading(state);
  return loading.loading;
};

const selectors = {
  getLoading,
  getIsLoading,
};

export { actions, reducers, slice, selectors };
