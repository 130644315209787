import * as React from 'react';
import { connect } from 'react-redux';

import { WebState, LastWeekState } from '@gerbil/types';
import { selectors } from '@gerbil/last-week';
const { getLastWeekHours } = selectors;

import ButtonRefresh from '../button-refresh';
import Header from '../header';
import { LayoutPage } from '../layout';
import { Table, Tr, Tbody, Th, Thead } from '../table';

import UserRowHours from './user-row-hours';
import UserRowHoursDetail from './user-row-hours-detail';

interface Props {
  data: LastWeekState;
}

export const LastWeek: React.SFC<Props> = ({ data }) => {
  const users = Object.keys(data);
  return (
    <LayoutPage>
      <Header type="page" text="Last Week's Hours">
        <ButtonRefresh />
      </Header>

      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th align="center">Tracked</Th>
          </Tr>
        </Thead>

        <Tbody>
          {users.map((userName: string) => {
            const userData = data[userName];
            const { realName, total } = userData;
            return (
              <UserRowHours
                key={realName}
                userName={realName}
                billed={total}
              >
                {userData.entryName.map((c, i) => (
                  <UserRowHoursDetail
                    key={realName+c}
                    client={c}
                    billed={userData.hours[i]}
                  />
                ))}
              </UserRowHours>
            );
          })}
        </Tbody>
      </Table>
    </LayoutPage>
  );
};

const mapState = (state: WebState) => ({
  data: getLastWeekHours(state),
});
export default connect(mapState)(LastWeek);
