import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import {
  createStore as reduxCreateStore,
  applyMiddleware,
  Middleware,
  Reducer,
  compose,
} from 'redux';
import cofxMiddleware from 'redux-cofx';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cofxMiddlewareCreator from 'redux-router-cofx';

import { WebState } from '@gerbil/types';
import { webRouterMap } from '@gerbil/router';

interface Props {
  initState?: { [key: string]: any };
  rootReducer: Reducer<WebState>;
}

export default function createStore({ initState, rootReducer }: Props) {
  const cofxRouterMiddleware = cofxMiddlewareCreator(webRouterMap);
  const history = createBrowserHistory();
  const routerReducer = connectRouter(history)(rootReducer);
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['token', 'tokenExpiration'],
  };
  const persistedReducer = persistReducer(persistConfig, routerReducer);
  const middleware: Middleware[] = [
    cofxMiddleware,
    cofxRouterMiddleware.middleware,
    routerMiddleware(history),
  ];

  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger);
  }

  const store = reduxCreateStore(
    persistedReducer,
    initState,
    compose(applyMiddleware(...middleware)),
  );
  const persistor = persistStore(store);

  return {
    store,
    history,
    persistor,
  };
}
