import * as React from 'react';

interface Props {
  // children as function, pass parent width to VictoryChart
  children?: (width: number) => React.ReactNode;
}

interface State {
  width: number;
}

class ChartWrap extends React.Component<Props, State> {
  containerNode: HTMLElement = null;

  state = {
    width: 0,
  };

  saveRef = (ref: any) => {
    this.containerNode = ref;
  };

  measure = () => {
    if (!this.containerNode) return;
    const { clientWidth } = this.containerNode;

    this.setState({
      width: clientWidth,
    });
  };

  componentDidMount() {
    this.measure();
    window.addEventListener('resize', this.measure);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.measure);
  }

  render() {
    const { width } = this.state;
    const { children } = this.props;

    return (
      <div ref={this.saveRef} style={{ width: '100%' }}>
        {children(width)}
      </div>
    );
  }
}

export default ChartWrap;
