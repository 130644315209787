import { call, put, createEffect } from 'redux-cofx';

import apiFetch from '@gerbil/fetch';

import { actions } from './slice';
import { Forecast, DataPointMap, DateMap, AvailabilityMap } from '@gerbil/types';

interface ForecastResponse {
  'forecasted_hours': DateMap;
  'underutilized': DateMap;
  'role': DateMap;
  'work_week': DateMap;
}

function pickAttributes(role: string, a: ForecastResponse): AvailabilityMap {
  const indexes = Object.keys(a.role).filter((key: string, index: number) => {
    return a.role[key] === role
  })

  const open = indexes.reduce((acc: any, cur: string) => {
    acc[a.work_week[cur]] =  a.underutilized[cur]
    return acc
  }, {})

  const booked = indexes.reduce((acc: any, cur: string) => {
    acc[a.work_week[cur]] =  a.forecasted_hours[cur]
    return acc
  }, {})

  return {
    open,
    booked 
  }
}

function transformForecast(a: ForecastResponse): Forecast {
  const data = {
    designers: pickAttributes('designer', a),
    engineers: pickAttributes('engineer', a),
    pms: pickAttributes('pm', a)
  }

  const billableBooked = Object.keys(data.designers.booked).reduce(
    (acc: DataPointMap, k: string) => {
      acc[k] = (
        data.engineers.booked[k] + 
        data.pms.booked[k] + 
        data.designers.booked[k]
      );
      return acc;
    }, {})

  const billableOpen = Object.keys(data.designers.open).reduce((acc: DataPointMap, k: string) => {
    acc[k] = (
      data.engineers.open[k] + 
      data.pms.open[k] + 
      data.designers.open[k]
    );
    return acc;
  }, {})

  return {
    billableBooked,
    billableOpen, 
    designersBooked: data.designers.booked,
    designersOpen: data.designers.open,
    engineersBooked: data.engineers.booked,
    engineersOpen: data.engineers.open,
    productManagersBooked: data.pms.booked,
    productManagersOpen: data.pms.open,
  };
}

export function* onFetchForecast() {
  const res = yield call(apiFetch, '/availabilities');

  if (res.status < 200 || res.status >= 300) {
    // do something with error
    return;
  }

  const data = transformForecast(res.body as ForecastResponse);
  console.log(JSON.stringify(data));
  yield put(actions.setForecast(data));
}

const fetchForecast = () => createEffect(onFetchForecast);

export const effects = { fetchForecast, onFetchForecast };
