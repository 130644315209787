import * as React from 'react';
import styled from 'styled-components';

interface TheadProps {
  className?: string;
  children?: React.ReactNode;
}

const Thead: React.SFC<TheadProps> = ({ className, children }) => (
  <thead className={className}>{children}</thead>
);

const StyledThead = styled(Thead)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;
  border-bottom: 0.0625rem solid ${(props) => props.theme.tableBorder};
`;

export default StyledThead;
