import * as React from 'react';
import styled from 'styled-components';

interface TrProps {
  className?: string;
  children?: React.ReactNode;
}

const Tr: React.SFC<TrProps> = ({ className, children }) => (
  <tr className={className}>{children}</tr>
);

const StyledTr = styled(Tr)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;
  position: relative;
  border-bottom: 0.0625rem solid ${(props) => props.theme.tableBorder};

  &:last-child {
    border-bottom: none;
  }
`;

export default StyledTr;
