import * as React from 'react';
import styled from 'styled-components';

import { Text } from '../typography';

interface UserProps {
  name?: string;
  image?: string;
  className?: string;
}

const User: React.SFC<UserProps> = ({ name, image, className }) => (
  <div className={className}>
    {image && (
      <AvatarWrap>
        <Avatar src={image} alt={name} />
      </AvatarWrap>
    )}

    {name && <Name>{name}</Name>}
  </div>
);

const StyledUser = styled(User)`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: ${(props) => (props.image ? '2.25rem 1fr' : '1fr')};
  align-items: center;
  width: auto;
  height: auto;
  margin: 0 0;
  padding: 0 0;
`;

const AvatarWrap = styled.span`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 0;
  padding: 0 0;
  border-radius: 1.125rem;
  border: 0.0625rem solid #a3a3a3;
  overflow: hidden;
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: center;
`;
const Name = styled(Text)`
  margin: 0 0;
  font-weight: ${(props) => props.theme.fontWeightSemi};
  line-height: 100%;
`;

export default StyledUser;
