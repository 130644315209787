import { ForecastState } from '@gerbil/types';

export const defaultForecast: ForecastState = {
  billableBooked: {},
  billableOpen: {},
  designersBooked: {},
  designersOpen: {},
  engineersBooked: {},
  engineersOpen: {},
  productManagersBooked: {},
  productManagersOpen: {},
};
