import { DataPointMap, Forecast } from '@gerbil/types';

interface Datum {
  date: string;
  hours: number;
}

export interface ChartData {
  dates: string[];
  booked: Datum[];
  open: Datum[];
}

export function transformForecastForChart(
  booked: DataPointMap,
  open: DataPointMap,
): ChartData {
  const bookedDates = Object.keys(booked);
  const bookedData = [] as Datum[];
  const openData = [] as Datum[];
  bookedDates.forEach((date: string) => {
    const sum = booked[date] + open[date];
    const bookedRatio = (booked[date] / sum) * 100;
    const openRatio = 100 - bookedRatio;
    bookedData.push({
      date,
      hours: bookedRatio,
    });
    openData.push({
      date,
      hours: openRatio,
    });
  });

  return {
    dates: bookedDates,
    booked: bookedData,
    open: openData,
  };
}

export interface ForecastChartData {
  billable: ChartData;
  designers: ChartData;
  engineers: ChartData;
  productManagers: ChartData;
}

export function transformAllForecastsDataForChart(
  data: Forecast,
): ForecastChartData {
  return {
    billable: transformForecastForChart(data.billableBooked, data.billableOpen),
    designers: transformForecastForChart(
      data.designersBooked,
      data.designersOpen,
    ),
    engineers: transformForecastForChart(
      data.engineersBooked,
      data.engineersOpen,
    ),
    productManagers: transformForecastForChart(
      data.productManagersBooked,
      data.productManagersOpen,
    ),
  };
}
