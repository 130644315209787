import * as React from 'react';
import styled from 'styled-components';

import { H1, H3 } from '../typography';

interface IHeaderProps {
  type: 'page' | 'section';
  color?: 'dark' | 'light';
  text?: string;
  children?: React.ReactNode;
  className?: string;
}

const Header: React.SFC<IHeaderProps> = ({
  type = 'section',
  color = 'dark',
  text,
  children,
  className,
}) => (
  <header className={className}>
    {type === 'page' && <TextPage>{text}</TextPage>}
    {type === 'section' && <TextSection>{text}</TextSection>}
    {children && <ChildWrap>{children}</ChildWrap>}
  </header>
);

const StyledHeader = styled(Header)`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0 0 0;
  margin-bottom: ${(props) =>
    props.type === 'page' ? (props) => props.theme.gridGapSmall : '1rem'};
  padding: 0 0;
  text-align: left;
  color: ${(props) =>
    props.color === 'light'
      ? (props) => props.theme.typeHeadlineLight
      : (props) => props.theme.typeHeadlineDark};

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: ${(props) =>
      props.type === 'page' ? (props) => props.theme.gridGapMedium : '1rem'};
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    margin-bottom: ${(props) =>
      props.type === 'page' ? (props) => props.theme.gridGap : '1rem'};
  }
`;

const ChildWrap = styled.section`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 1rem 0 0;
  padding: 0 0;

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    width: auto;
    margin: 0 0;
    padding: 0 0 0 3rem;
  }
`;

const TextPage = styled(H1)`
  display: flex;
  width: auto;
  margin: 0 0;
  color: inherit;
`;

const TextSection = styled(H3)`
  display: flex;
  width: auto;
  margin: 0 0;
  color: inherit;
`;

export default StyledHeader;
