import robodux from '@gerbil/robodux';
import { WebState, ForecastState } from '@gerbil/types';

import { defaultForecast } from './utils';
import { transformAllForecastsDataForChart } from './transform';

const slice = 'forecast';
const { actions, reducer } = robodux<ForecastState>({
  actions: {
    setForecast: (state: ForecastState, payload: ForecastState) => payload,
  },
  initialState: defaultForecast,
  slice,
});

const reducers = {
  [slice]: reducer,
};

const getForecast = (state: WebState) => state[slice];
const getForecastBookedRatio = (state: WebState) => {
  const forecast = getForecast(state);
  return transformAllForecastsDataForChart(forecast);
};
const selectors = {
  getForecast,
  getForecastBookedRatio,
};

export { actions, reducers, slice, selectors };
