import robodux from '@gerbil/robodux';
import { MoneyState, Money } from '@gerbil/types';

const slice = 'money';

const defaultMoney: Money = {
  dates: [],
  dollars: {},
};

const { actions, reducer } = robodux<MoneyState>({
  actions: {
    setMoney: (state: MoneyState, payload: Money) => payload,
  },
  initialState: defaultMoney,
  slice,
});

const reducers = {
  [slice]: reducer,
};

export { actions, reducers, slice };
