import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled, { keyframes } from 'styled-components';

import { effects } from '@gerbil/bootup';
const { fetchData } = effects;
import { selectors } from '@gerbil/loading';
const { getIsLoading } = selectors;
import { WebState } from '@gerbil/types';

import Button from '../button';
import Icon from '../icon';

interface IDispatch {
  onClick: () => void;
  text?: string;
}

interface IMapState {
  isLoading: boolean;
}

interface IProps extends IMapState, IDispatch {}

export const ButtonRefresh = ({
  onClick,
  isLoading,
  text = 'Refreshing All Data...',
}: IProps) => {
  if (isLoading) {
    return (
      <RefreshingWrap>
        <IconWrap>
          <Icon icon="UIRefresh" color="gold" />
        </IconWrap>
        <Text>{text}</Text>
      </RefreshingWrap>
    );
  }

  return (
    <Button type="inline" icon="UIRefresh" onClick={() => onClick()}>
      Refresh
    </Button>
  );
};

const RefreshingWrap = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
`;

const Text = styled.span`
  font-family: ${(props) => props.theme.fontFamilyUI};
  font-weight: ${(props) => props.theme.fontWeightSemi};
  font-size: 0.875rem;
  line-height: 100%;
  letter-spacing: 0.058rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.darkGray};
  opacity: 0.75;
`;

const Rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const IconWrap = styled.span`
  display: flex;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 0.5rem 0 0;
  padding: 0 0;
  animation: ${Rotate360} 1200ms ${(props) => props.theme.circInOut} infinite;
`;

export const mapState = (state: WebState) => ({
  isLoading: getIsLoading(state),
});

export const mapDispatch = (dispatch: Dispatch) => ({
  onClick: () => dispatch(fetchData()),
});

export default connect<IMapState, IDispatch>(
  mapState,
  mapDispatch,
)(ButtonRefresh);
