import * as React from 'react';
import styled from 'styled-components';

import Header from '../header';

interface ChartHeaderProps {
  text?: string;
  children?: React.ReactNode;
}

const ChartHeader: React.SFC<ChartHeaderProps> = ({ text, children }) => (
  <StyledHeader type="section" color="light" text={text} children={children} />
);

const StyledHeader = styled(Header)`
  grid-area: ChartHeader;
  margin-bottom: 0;
`;

export default ChartHeader;
