import * as React from 'react';
import styled from 'styled-components';

import { Table, Tbody, Tr, Td } from '../table';
import { Text } from '../typography';

interface UserRowHoursDetailProps {
  client?: string;
  forecasted?: number;
  billed?: number;
  diff?: number;
}

const UserRowHoursDetail: React.SFC<UserRowHoursDetailProps> = ({
  client,
  billed,
}) => (
  <Details>
    <Tbody>
      <DetailRow>
        <Detail align="left">
          <ClientName>{client}</ClientName>
        </Detail>
        <Detail align="center">{billed.toFixed(2)}</Detail>
      </DetailRow>
    </Tbody>
  </Details>
);

const Details = styled(Table)`
  border-radius: 0;
  border: none;
  border-bottom: 0.0625rem solid rgba(238, 238, 238, 1);
`;

const DetailRow = styled(Tr)`
  border: none;
`;

const Detail = styled(Td)`
  padding-top: 0.875rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
`;

const ClientName = styled(Text)`
  margin: 0 0 0 0;
  font-size: 0.75rem;
  line-height: 100%;
`;

export default UserRowHoursDetail;
