import * as React from 'react';
import styled from 'styled-components';

interface LayoutDataProps {
  className?: string;
  children?: React.ReactNode;
}

const LayoutData: React.SFC<LayoutDataProps> = ({ className, children }) => (
  <section className={className}>{children}</section>
);

const StyledLayoutData = styled(LayoutData)`
  display: grid;
  grid-gap: ${(props) => props.theme.gridGap};
  grid-template-columns: 100%;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;
`;

export default StyledLayoutData;
