import * as React from 'react';
import styled from 'styled-components';

import Icon from '../icon';

interface INavMainProps {
  children?: any;
  authenticated?: boolean;
  className?: string;
  navOpen?: boolean;
  toggleNav?: () => void;
}

class NavMain extends React.Component<INavMainProps> {
  render() {
    const { children, authenticated, className, toggleNav } = this.props;
    return (
      <header className={className} role="banner">
        {authenticated && (
          <MainNav>
            <Controls>
              <LogoWrap>
                <Icon icon="logoVeryMark" color="white" />
              </LogoWrap>

              <IconNav onClick={toggleNav}>
                <Icon icon="UINav" color="white" />
              </IconNav>
            </Controls>

            <Nav role="navigation" aria-label="App Navigation">
              {children}
            </Nav>
          </MainNav>
        )}

        <GerbilBrand>
          <Icon icon="logoGerbil" color="white" />
        </GerbilBrand>
      </header>
    );
  }
}

const StyledNavMain = styled(NavMain)`
  grid-area: header;
  display: ${(props) => (props.navOpen === true ? 'flex' : 'none')};
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 0;
  padding: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(47, 48, 50, 1);
  z-index: 999;
  overflow: scroll;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    display: flex;
    position: relative;
    top: initial;
    left: initial;
  }
`;

const MainNav = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: 0 0;
`;

const Controls = styled.section`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 0;
  padding: ${(props) => props.theme.gridGapSmall};

  @media (min-width: ${(props) => props.theme.bpMedium}) {
    padding: ${(props) => props.theme.gridGapMedium};
  }

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    padding: 2rem 2rem 0 2rem;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 2rem;
  height: 2.5625rem;
  margin: 0 0;
  padding: 0 0;
`;

const IconNav = styled.span`
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 10rem;
  height: auto;
  margin: 3rem 0;
  padding: 0 0;

  @media (min-width: ${(props) => props.theme.bpLarge}) {
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    max-width: 100%;
    margin: 3rem 0;
  }
`;

const GerbilBrand = styled.span`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 0.8125rem;
  height: 0.875rem;
  margin: 0 2rem 3rem;
  padding: 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.1;
  cursor: default;
  pointer-events: none;
`;

export default StyledNavMain;
